import React from 'react';

const Chip = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="6"
        width="12"
        height="12"
        rx="3"
        className={`  fill-[#C8CDD8] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16C2 15.4477 2.44772 15 3 15H4C4.55228 15 5 15.4477 5 16C5 16.5523 4.55228 17 4 17H3C2.44772 17 2 16.5523 2 16Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 16C19 15.4477 19.4477 15 20 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H20C19.4477 17 19 16.5523 19 16Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.4477 2.44772 11 3 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H3C2.44772 13 2 12.5523 2 12Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12C19 11.4477 19.4477 11 20 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H20C19.4477 13 19 12.5523 19 12Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 7.44772 2.44772 7 3 7H4C4.55228 7 5 7.44772 5 8C5 8.55228 4.55228 9 4 9H3C2.44772 9 2 8.55228 2 8Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8C19 7.44772 19.4477 7 20 7H21C21.5523 7 22 7.44772 22 8C22 8.55228 21.5523 9 21 9H20C19.4477 9 19 8.55228 19 8Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C16.5523 2 17 2.44772 17 3V4C17 4.55228 16.5523 5 16 5C15.4477 5 15 4.55228 15 4V3C15 2.44772 15.4477 2 16 2Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 19C16.5523 19 17 19.4477 17 20V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V20C15 19.4477 15.4477 19 16 19Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V3C11 2.44772 11.4477 2 12 2Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19C12.5523 19 13 19.4477 13 20V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V20C11 19.4477 11.4477 19 12 19Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V3C7 2.44772 7.44772 2 8 2Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 19C8.55228 19 9 19.4477 9 20V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V20C7 19.4477 7.44772 19 8 19Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
    </svg>
  );
};

export default Chip;
