import Sidebar from "../components/sidebar";
import React from "react";
import { useParams } from "react-router-dom";

const AgencyDashboard = () => {
  const { id: agencyId } = useParams();

  const sidebarOptions = [
    {
      name: "Dashboard",
      icon: "category",
      link: `/agency/${agencyId}`,
    },
    {
      name: "Launchpad",
      icon: "clipboardIcon",
      link: `/agency/${agencyId}/launchpad`,
    },
    {
      name: "Billing",
      icon: "payment",
      link: `/agency/${agencyId}/billing`,
    },
    {
      name: "Settings",
      icon: "settings",
      link: `/agency/${agencyId}/settings`,
    },
    {
      name: "Sub Accounts",
      icon: "person",
      link: `/agency/${agencyId}/all-subaccounts`,
    },
    {
      name: "Team",
      icon: "shield",
      link: `/agency/${agencyId}/team`,
    },
  ];
  return (
    <div>
      <Sidebar id={agencyId!} type="agency" />
    </div>
  );
};

export default AgencyDashboard;
