import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { auth, googleProvider } from "../lib/firebase-client";
import { useSignInMutation } from "../app/services/authApi";
import { useAppDispatch } from "../app/hooks";
import { setAuth } from "../app/features/authSlice";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { CustomError } from "../types";
import { useToast } from "../components/ui/use-toast";

// Define the shape of form data
interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const form = useForm<SignInFormData>();
  const [signIn, { data, isSuccess, isLoading, isError, error }] =
    useSignInMutation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();

  const handleSignIn = async (email: string, password: string) => {
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        email,
        password
      );
      const token = await userCredential.user?.getIdToken();
      if (token) {
        const { email, displayName, photoURL, uid } = userCredential.user!;
        const user = {
          token,
          email: email ?? "",
          name: displayName ?? "",
          avatarUrl: photoURL ?? "",
          uid,
        };

        console.log(user)
        dispatch(setAuth(user));

        //await signIn(user);
      }
    } catch (error) {
      console.error("Error signing in:", (error as Error).message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const userCredential = await auth.signInWithPopup(googleProvider);
      const token = await userCredential.user?.getIdToken();
      if (token) {
        const { email, displayName, photoURL, uid } = userCredential.user!;
        const user = {
          token,
          email: email ?? "",
          name: displayName ?? "",
          avatarUrl: photoURL ?? "",
          uid,
        };
        // await signIn(user);
        dispatch(setAuth(user));
      }
    } catch (error) {
      console.error("Error signing in with Google:", (error as Error).message);
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(
        setAuth({
          id: data.user.id,
          name: data.user.name,
          email: data.user.email,
          avatarUrl: data.user.avatarUrl,
          token: data.token,
        })
      );
      toast({
        title: "Signed in successfully",
      });
    } else if (isError) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Oops! Something went wrong";
      toast({
        title: "Failed to sign in",
        description: errorMessage,
        variant: "destructive",
      });
      return;
    }
  }, [isSuccess, isError]);

  const onSubmit: SubmitHandler<SignInFormData> = (data) => {
    handleSignIn(data.email, data.password);
  };

  return (
    <div>
      <h2>Sign In</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Email" {...field} />
                </FormControl>
                <FormDescription>Enter your email address</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormDescription>Enter your password</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Signing in..." : "Sign In"}
          </Button>
        </form>
      </Form>
      <Button onClick={handleGoogleSignIn} disabled={isLoading}>
        {isLoading ? "Signing in..." : "Sign In with Google"}
      </Button>
      <p>
        Don't have an account? <Link to="/sign-up">Sign up here</Link>
      </p>
    </div>
  );
};

export default SignIn;
