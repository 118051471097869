import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

// Define the base query with type checking
const baseQuery = fetchBaseQuery({
  baseUrl: 'http://localhost:3000', // Your backend URL
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the API with TypeScript types
export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: (builder) => ({
    fetchProtectedData: builder.query<string, void>({
      query: () => '/protected', // Endpoint for fetching protected data
    }),
  }),
});

// Export hooks for using the query in components
export const { useFetchProtectedDataQuery } = api;
