import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  avatarUrl: "",
  email: "",
  phoneNo: "",
  role: "",
  token: null,
  uid: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    createAuth: (state, action) => {},

    setAuth: (state, action) => {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          uid: action.payload.uid,
          name: action.payload.name,
          avatarUrl: action.payload.avatarUrl,
          email: action.payload.email,
          phoneNo: action.payload.phoneNo,
          role: action.payload.role,
          token: action.payload.token,
        })
      );
      state.uid = action.payload.uid;
      state.name = action.payload.name;
      state.avatarUrl = action.payload.avatarUrl;
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.role = action.payload.role;
      state.token = action.payload.token;
    },

    logout: (state) => {
      localStorage.clear();
      state.name = "";
      state.avatarUrl = "";
      state.uid = null;
      state.email = "";
      state.phoneNo = "";
      state.role = "";
      state.token = null;
    },
  },
});

export const selectAuth = (state: any) => state.auth;

export const { createAuth, setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
