import React from 'react';

const Shield = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.66228C3 5.37099 3.82629 4.22457 5.05132 3.81623L11.0513 1.81623C11.6671 1.61096 12.3329 1.61096 12.9487 1.81623L18.9487 3.81623C20.1737 4.22457 21 5.37099 21 6.66228V12C21 17.502 15.4398 20.8417 13.0601 22.0192C12.3875 22.3519 11.6125 22.3519 10.9399 22.0192C8.56019 20.8417 3 17.502 3 12V6.66228Z"
        className={`  fill-[#C8CDD8] text-xl transition-all`}
      />
      <path
        d="M12 22.2687V1.66229C12.3204 1.66229 12.6408 1.71361 12.9487 1.81624L18.9487 3.81624C20.1737 4.22459 21 5.371 21 6.66229V12C21 17.25 15.9375 20.5313 13.4063 21.8438C13.2847 21.9068 13.1691 21.9652 13.0601 22.0192C12.7238 22.1856 12.3619 22.2687 12 22.2687Z"
        className={`  fill-[#70799A] text-xl transition-all`}
      />
    </svg>
  );
};

export default Shield;
