import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

// Base URL from environment variables
const url = process.env.REACT_APP_BASE_URI as string;

export const subAccountApi = createApi({
  reducerPath: "subAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}subaccount/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["subAccount"],
  endpoints: (builder) => ({
    createSubAccount: builder.mutation({
      query: (data) => ({
        url: "create-subaccount",
        method: "POST",
        body: data,
      }),
    }),

    getSubAccounts: builder.query({
      query: ({ agencyId }) => ({
        url: "sub-accounts",
        method: "GET",
        params: { agencyId },
      }),
    }),

    getsubAccountById: builder.query({
      query: ({ id }) => ({
        url: `get-subAccount/${id}`,
        method: "GET",
        params: { id },
      }),
    }),

    // Other endpoints can be defined here
  }),
});

export const {
  useCreateSubAccountMutation,
  useGetSubAccountsQuery,
  useGetsubAccountByIdQuery,
} = subAccountApi;
