import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../ui/use-toast";
import * as z from "zod";
import { SubAccount, CustomError } from "../../types";
import FileUpload from "../common/FileUpload";
import Loading from "../global/Loading";
import { useCreateSubAccountMutation } from "../../app/services/subAccountApi";
import { useAppSelector } from "../../app/hooks";
import { selectAuth } from "../../app/features/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetUserByEmailQuery } from "../../app/services/authApi";

type Props = {
  data?: Partial<SubAccount>;
};

const FormSchema = z.object({
  name: z
    .string()
    .min(2, { message: "Sub account name must be at least 2 chars." }),
  companyEmail: z.string().min(1),
  companyPhone: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  zipCode: z.string().min(1),
  state: z.string().min(1),
  country: z.string().min(1),
  subAccountLogo: z.string().min(1),
  agencyId: z.string().min(1),
});

const SubAccountDetails = (props: Props) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    uid: userId,
    email: userEmail,
    name: userName,
  } = useAppSelector(selectAuth);
  const { data: userData } = useGetUserByEmailQuery({ email: userEmail });
  const agencyId = userData?.Agency?._id;

  const [
    createSubAccount,
    { data, isLoading: isCreating, error, isError, isSuccess },
  ] = useCreateSubAccountMutation();

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: props.data?.name,
      companyEmail: props.data?.companyEmail,
      companyPhone: props.data?.companyPhone,
      address: props.data?.address,
      city: props.data?.city,
      zipCode: props.data?.zipCode,
      state: props.data?.state,
      country: props.data?.country,
      subAccountLogo: props.data?.subAccountLogo,
      agencyId: agencyId,
    },
  });

  const isLoading = form.formState.isSubmitting;

  useEffect(() => {
    if (props.data) {
      form.reset(props.data);
    }
  }, [props.data, form]);

  const handleSubmit: SubmitHandler<z.infer<typeof FormSchema>> = async (
    values
  ) => {
    console.log("Form submitted with values: ", values); // Debug log
    try {
      const response = await createSubAccount(values).unwrap();
      console.log("Sub account created successfully: ", response); // Debug log
      toast({
        title: "Sub account created successfully",
      });

      navigate(`/agency/${values.agencyId}/subaccount/${response._id}`);
    } catch (error) {
      const customError = error as CustomError;
      const errorMessage =
        customError?.data?.error || "Agency could not be created";
      console.error("Failed to create agency: ", errorMessage); // Debug log
      toast({
        title: "Failed to create agency",
        description: errorMessage,
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Sub Account Information</CardTitle>
        <CardDescription>Please enter business details</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Form submit event triggered: ", agencyId); // Debug log
              form.handleSubmit(handleSubmit)();
            }}
            className="space-y-4"
          >
            <FormField
              disabled={isLoading}
              control={form.control}
              name="subAccountLogo"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Account Logo</FormLabel>
                  <FormControl>
                    <FileUpload
                      apiEndpoint="subaccountLogo"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex md:flex-row gap-4">
              <FormField
                disabled={isLoading}
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Account Name</FormLabel>
                    <FormControl>
                      <Input
                        required
                        placeholder="Your agency name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={isLoading}
                control={form.control}
                name="companyEmail"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Account Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex md:flex-row gap-4">
              <FormField
                disabled={isLoading}
                control={form.control}
                name="companyPhone"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Account Phone Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Phone" required {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              disabled={isLoading}
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <Input required placeholder="123 st..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex md:flex-row gap-4">
              <FormField
                disabled={isLoading}
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input required placeholder="City" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={isLoading}
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input required placeholder="State" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                disabled={isLoading}
                control={form.control}
                name="zipCode"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Zipcode</FormLabel>
                    <FormControl>
                      <Input required placeholder="Zipcode" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              disabled={isLoading}
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Country</FormLabel>
                  <FormControl>
                    <Input required placeholder="Country" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? <Loading /> : "Save Account Information"}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default SubAccountDetails;
