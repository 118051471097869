import LogoutButton from "../components/common/LogoutButton";
import React from "react";

const Site = () => {
  return (
    <div>
      <h3>Site Page</h3>
      <LogoutButton />
    </div>
  );
};

export default Site;
