import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./services/authApi";
import { api } from "./services/api";
import authReducer from "./features/authSlice";
import { agencyApi } from "./services/agencyApi";
import { subAccountApi } from "./services/subAccountApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [agencyApi.reducerPath]: agencyApi.reducer,
    [subAccountApi.reducerPath]: subAccountApi.reducer,

    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(agencyApi.middleware)
      .concat(subAccountApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
