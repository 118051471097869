// LogoutButton.tsx
import { auth } from "../../lib/firebase-client";
import { useAppDispatch } from "../../app/hooks";
import React from "react";
import { logout } from "../../app/features/authSlice";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

const LogoutButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      dispatch(logout());
      navigate("/sign-in");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return <Button onClick={handleLogout}>Logout</Button>;
};

export default LogoutButton;
