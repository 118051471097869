import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import useAuthMiddleware from "./hooks/useAuthMiddleware";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import Site from "./pages/Site";
import Agency from "./pages/Agency";
import SubAccount from "./pages/SubAccount";
import NotFound from "./pages/NotFound";
import { useAppDispatch } from "./app/hooks";
import { setAuth } from "./app/features/authSlice";
import AgencyDashboard from "./pages/AgencyDashboard";

const App = () => {
  useAuthMiddleware();
  const dispatch = useAppDispatch();

  const auth = JSON.parse(localStorage.getItem("auth") || "{}");

  useEffect(() => {
    dispatch(setAuth(auth));
  }, [dispatch, auth]);

  return (
    <Routes>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/agency/*" element={<Agency />} />
      <Route path="/agency/:id" element={<AgencyDashboard />} />
      <Route path="/subaccount/*" element={<SubAccount />} />
      <Route path="/site" element={<Site />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
