import { useNavigate, useParams } from "react-router-dom";
import AgencyDetails from "../components/forms/AgencyDetails";
import { useAppSelector } from "../app/hooks";
import { selectAuth } from "../app/features/authSlice";
import { useGetAgencyQuery } from "../app/services/agencyApi";
import { useEffect } from "react";

const Agency = () => {
  const navigate = useNavigate();
  const { email } = useAppSelector(selectAuth);
  const { data } = useGetAgencyQuery({ email });

  useEffect(() => {
    const initialize = async () => {
      if (!data) return;

      const agencyId = data?.agency?._id;

      // Get the user's details
      const user = data?.user;
      if (agencyId) {
        if (
          user?.role === "SUBACCOUNT_GUEST" ||
          user?.role === "SUBACCOUNT_USER"
        ) {
          navigate("/subaccount");
        } else if (
          user?.role === "AGENCY_OWNER" ||
          user?.role === "AGENCY_ADMIN"
        ) {
          // Uncomment and adjust these lines as needed
          // if (plan) {
          //   navigate(`/agency/${agencyId}/billing?plan=${plan}`);
          // } else if (state) {
          //   const [statePath, stateAgencyId] = state.split("___");
          //   if (!stateAgencyId) return <div>Not authorized</div>;
          //   navigate(`/agency/${stateAgencyId}/${statePath}?code=${code}`);
          // } else {
          //   navigate(`/agency/${agencyId}`);
          // }
          navigate(`/agency/${agencyId}`);
        } else {
          // Handle unauthorized access
          return <div>Not authorized</div>;
        }
      }
    };

    initialize();
  }, [navigate, data]);

  const agency = data?.agency;

  return (
    <div className="flex justify-center items-center mt-4">
      <div className="max-w-[850px] border-[1px] p-4 rounded-xl">
        <h1 className="text-4xl">Create An Agency</h1>
        <AgencyDetails
          // Uncomment this line when the data is required
          data={{
            name: agency?.name,
            companyEmail: agency?.companyEmail,
            companyPhone: agency?.companyPhone,
            whiteLabel: agency?.whiteLabel,
            address: agency?.address,
            city: agency?.city,
            zipCode: agency?.zipCode,
            state: agency?.state,
            country: agency?.country,
            agencyLogo: agency?.agencyLogo,
            uid: agency?.uid,
          }}
        />
      </div>
    </div>
  );
};

export default Agency;
