import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

// Define the structure of the Agency and User data
interface Agency {
  _id: string;
  name: string;
  agencyLogo: string;
  companyEmail: string;
  companyPhone: string;
  whiteLabel: boolean;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  goal: number;
  createdAt: string;
  updatedAt: string;
  uid?: string;
}

interface User {
  _id?: string;
  uid?: string;
  name: string;
  email: string;
  avatarUrl?: string;
  role: string;
  agencyId: string;
  createdAt: string;
  updatedAt: string;
}

interface CreateAgencyResponse {
  message: string;
  agency: Agency;
  user: User;
}

interface CreateAgencyPayload {
  name: string;
  agencyLogo: string;
  companyEmail: string;
  companyPhone: string;
  whiteLabel: boolean;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  goal?: number;
  uid?: string;
}

// Base URL from environment variables
const url = process.env.REACT_APP_BASE_URI as string;

export const agencyApi = createApi({
  reducerPath: "agencyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}agency/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Agency"],
  endpoints: (builder) => ({
    createAgency: builder.mutation<CreateAgencyResponse, CreateAgencyPayload>({
      query: (data) => ({
        url: "create-agency",
        method: "POST",
        body: data,
      }),
    }),

    getAgency: builder.query({
      query: ({ email }) => ({
        url: "my-agency",
        method: "GET",
        params: { email },
      }),
    }),

    getAgencyById: builder.query({
      query: ({ id }) => ({
        url: `get-agency/${id}`,
        method: "GET",
        params: { id },
      }),
    }),

    // Other endpoints can be defined here
  }),
});

export const { useCreateAgencyMutation, useGetAgencyQuery, useGetAgencyByIdQuery } = agencyApi;
