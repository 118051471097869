import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const useAuthMiddleware = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null);
  const auth = getAuth();
  const redirectDoneRef = useRef<boolean>(false);

  useEffect(() => {
    // Listener for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsSignedIn(!!user);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (isSignedIn === null) return; 

    if (redirectDoneRef.current) return; 

    const url = new URL(window.location.href);
    const searchParams = url.searchParams.toString();
    const hostname = window.location.hostname;

    const pathWithSearchParams = `${url.pathname}${
      searchParams.length > 0 ? `?${searchParams}` : ""
    }`;

    // Get subdomain if exists
    const domainParts = hostname.split(".");
    let customSubDomain = "";

    if (domainParts.length > 2) {
      customSubDomain = domainParts[0];
    }

    // Function to navigate only if necessary
    const conditionalNavigate = (targetPath: string) => {
      if (location.pathname !== targetPath) {
        navigate(targetPath, { replace: true });
        redirectDoneRef.current = true;
      }
    };

    // Handle routing based on authentication status and subdomain
    if (customSubDomain) {
      conditionalNavigate(`/${customSubDomain}${pathWithSearchParams}`);
    } else if (!isSignedIn) {
      if (url.pathname !== "/sign-in" && url.pathname !== "/sign-up") {
        conditionalNavigate("/sign-in");
      }
    } else {
      if (url.pathname === "/sign-in" || url.pathname === "/sign-up") {
        conditionalNavigate("/");
      } else if (
        url.pathname === "/" ||
        (url.pathname === "/site" && hostname === process.env.REACT_APP_DOMAIN)
      ) {
        conditionalNavigate("/site");
      } else if (
        url.pathname.startsWith("/agency") ||
        url.pathname.startsWith("/subaccount")
      ) {
        conditionalNavigate(pathWithSearchParams);
      }
    }
  }, [navigate, location.pathname, isSignedIn]);

  return null;
};

export default useAuthMiddleware;
