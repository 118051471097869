// SignUp.tsx
import React from "react";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { auth, googleProvider } from "../lib/firebase-client";
import { useSignUpMutation } from "../app/services/authApi";
import { useAppDispatch } from "../app/hooks";
import { setAuth } from "../app/features/authSlice";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";

// Define the shape of form data
interface SignUpFormData {
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const form = useForm<SignUpFormData>();
  const [signUp] = useSignUpMutation();
  const dispatch = useAppDispatch();

  const handleSignUp = async (email: string, password: string) => {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const token = await userCredential.user?.getIdToken();
      if (token) {
        const { email, displayName, photoURL, uid } = userCredential.user!;
        const user = {
          token,
          email: email ?? "",
          name: displayName ?? "",
          avatarUrl: photoURL ?? "",
          uid: uid,
          password: password ?? "",
        };
        await signUp(user);
        dispatch(
          setAuth({
            uid: uid,
            name: displayName ?? "",
            avatarUrl: photoURL ?? "",
            email: email ?? "",
            phoneNo: "",
            role: "",
            token: token,
          })
        );
      }
    } catch (error) {
      console.error("Error signing up:", (error as Error).message);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const userCredential = await auth.signInWithPopup(googleProvider);
      const token = await userCredential.user?.getIdToken();
      if (token) {
        const { email, displayName, photoURL, uid } = userCredential.user!;
        const user = {
          token,
          email: email ?? "",
          name: displayName ?? "",
          avatarUrl: photoURL ?? "",
          uid,
        };
        await signUp(user);
        dispatch(
          setAuth({
            uid: uid,
            name: displayName ?? "",
            avatarUrl: photoURL ?? "",
            email: email ?? "",
            phoneNo: "",
            role: "",
            token: token,
          })
        );
      }
    } catch (error) {
      console.error("Error signing up with Google:", (error as Error).message);
    }
  };

  const onSubmit: SubmitHandler<SignUpFormData> = (data) => {
    handleSignUp(data.email, data.password);
  };

  return (
    <div>
      <h2>Sign Up</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Email" {...field} />
                </FormControl>
                <FormDescription>Enter your email address</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormDescription>Enter your password</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Sign Up</Button>
        </form>
      </Form>
      <Button onClick={handleGoogleSignUp}>Sign Up with Google</Button>
      <p>
        Already have an account? <Link to="/sign-in">Sign in here</Link>
      </p>
    </div>
  );
};

export default SignUp;
