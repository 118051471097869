import { Link } from "react-router-dom";
import { selectAuth } from "../../app/features/authSlice";
import { useAppSelector } from "../../app/hooks";
import { useGetAgencyByIdQuery } from "../../app/services/agencyApi";
import { useGetUserByEmailQuery } from "../../app/services/authApi";
import MenuOptions from "./MenuOptions";
import { useGetSubAccountsQuery } from "../../app/services/subAccountApi";
//import SubAccount from "@/pages/SubAccount";

type Props = {
  id: string;
  type: "agency" | "subaccount";
};

type SidebarOption = {
  name: string;
  icon: string;
  link: string;
};

const Sidebar = ({ id, type }: Props): JSX.Element | null => {
  const { email } = useAppSelector(selectAuth);
  const { data } = useGetUserByEmailQuery({ email: email });
  const agencyId = data?.Agency?._id;
  const { data: agencyData } = useGetAgencyByIdQuery({ id: agencyId });
  const { data: subAccountsData } = useGetSubAccountsQuery({
    agencyId: agencyId,
  });
  console.log("SUB: ", subAccountsData);

  if (!email || !agencyId || !agencyData) {
    return null;
  }

  const details = type === "agency" ? agencyData : "Get subaccount data";

  const iswhiteLabelledAgency = agencyData.whiteLabel;

  const subAccount = agencyData.companyEmail;

  let sidebarLogo = agencyData.agencyLogo || "Add default logo";

  if (!iswhiteLabelledAgency) {
    if (type === "subaccount") {
      sidebarLogo =
        agencyData.subAccount.find((subAccount: any) => subAccount.id === id)
          ?.subAccountLogo || agencyData.agencyLogo;
    }
  }

  const sidebarOpt: SidebarOption[] =
    type === "agency"
      ? [
          {
            name: "Dashboard",
            icon: "category",
            link: `/agency/${agencyId}`,
          },
          {
            name: "Launchpad",
            icon: "clipboardIcon",
            link: `/agency/${agencyId}/launchpad`,
          },
          {
            name: "Billing",
            icon: "payment",
            link: `/agency/${agencyId}/billing`,
          },
          {
            name: "Settings",
            icon: "settings",
            link: `/agency/${agencyId}/settings`,
          },
          {
            name: "Sub Accounts",
            icon: "person",
            link: `/agency/${agencyId}/all-subaccounts`,
          },
          {
            name: "Team",
            icon: "shield",
            link: `/agency/${agencyId}/team`,
          },
        ]
      : [
          {
            name: "Subaccount Dashboard",
            icon: "dashboard",
            link: `/subaccount/${agencyId}/dashboard`,
          },
        ];

  return (
    <>
      <MenuOptions
        defaultOpen={true}
        details={details}
        id={agencyId}
        sidebarLogo={sidebarLogo}
        sidebarOpt={sidebarOpt}
        subAccounts={subAccount}
        user={data}
      />
      <MenuOptions
        details={details}
        id={agencyId}
        sidebarLogo={sidebarLogo}
        sidebarOpt={sidebarOpt}
        subAccounts={subAccountsData}
        user={data}
      />
    </>
  );
};

export default Sidebar;
