import React, { useEffect, useMemo, useState } from "react";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "../ui/sheet";
import { Button } from "../ui/button";
import { ChevronsUpDown, Compass, Menu, PlusCircleIcon } from "lucide-react";
import clsx from "clsx";
import { AspectRatio } from "../ui/aspect-ratio";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Link } from "react-router-dom";
import { Separator } from "../ui/separator";
import CustomModal from "../global/CustomModal";
import SubAccountDetails from "../forms/SubAccountDetails";
import { icons } from "../../lib/constants";
import { SubAccount } from "../../types";

type Props = {
  defaultOpen?: boolean;
  details: any;
  user: any;
  id: string;
  sidebarLogo: string;
  sidebarOpt: any;
  subAccounts?: SubAccount[];
};

const MenuOptions = ({
  defaultOpen,
  details,
  user,
  id,
  sidebarLogo,
  sidebarOpt,
  subAccounts,
}: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openState = useMemo(
    () => (defaultOpen ? { open: true } : {}),
    [defaultOpen]
  );

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  return (
    <Sheet modal={false} {...openState}>
      <SheetTrigger
        asChild
        className="absolute left-4 top-4 z-[100] md:!hidden flex"
      >
        <Button variant="outline" size={"icon"}>
          <Menu />
        </Button>
      </SheetTrigger>

      <SheetContent
        showX={!defaultOpen}
        side={"left"}
        className={clsx(
          "bg-background/80 backdrop-blur-xl fixed top-0 border-r-[1px] p-6",
          {
            "hidden md:inline-block z-0 w-[300px]": defaultOpen,
            "inline-block md:hidden z-[100] w-full": !defaultOpen,
          }
        )}
      >
        <div className="overflow-y-auto max-h-full">
          <AspectRatio ratio={16 / 5}>
            <img
              src={sidebarLogo}
              alt="Sidebar Logo"
              className="rounded-md object-contain max-h-20"
            />
          </AspectRatio>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                className="w-full my-4 flex items-center justify-between py-8"
                variant="ghost"
              >
                <div className="flex items-center text-left gap-2 overflow-hidden">
                  <Compass />
                  <div className="flex flex-col overflow-hidden">
                    <span className="truncate">{details.name}</span>
                    <span className="text-muted-foreground truncate">
                      {details.address}
                    </span>
                  </div>
                </div>
                <div>
                  <ChevronsUpDown size={16} className="text-muted-foreground" />
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 h-80 mt-4 z-[200]">
              <Command className="rounded-lg">
                <CommandInput placeholder="Search Accounts..." />
                <CommandList className="pb-16">
                  <CommandEmpty>No results found</CommandEmpty>

                  {(user?.role === "AGENCY_OWNER" ||
                    user?.role === "AGENCY_ADMIN") &&
                    user?.Agency && (
                      <CommandGroup heading="Agency">
                        <CommandItem className="!bg-transparent my-2 text-primary border-[1px] border-border p-2 rounded-md hover:!bg-muted cursor-pointer transition-all">
                          <Link
                            to={`/agency/${user?.Agency?._id}`}
                            className="flex gap-4 w-full h-full"
                          >
                            <div className="relative w-16 flex-shrink-0">
                              <img
                                src={user?.Agency?.agencyLogo}
                                alt="Agency Logo"
                                className="rounded-full object-contain w-20 h-20"
                              />
                            </div>
                            <div className="flex flex-col flex-1 overflow-hidden">
                              <span className="truncate">
                                {user?.Agency?.name}
                              </span>
                              <span className="text-muted-foreground truncate">
                                {user?.Agency?.address}
                              </span>
                            </div>
                          </Link>
                        </CommandItem>
                      </CommandGroup>
                    )}

                  <CommandGroup heading="Accounts">
                    {Array.isArray(subAccounts)
                      ? subAccounts.map((subaccount: any) => (
                          <CommandItem key={subaccount.id}>
                            {defaultOpen ? (
                              <Link
                                to={`/subaccount/${subaccount.id}`}
                                className="flex gap-4 w-full h-full"
                              >
                                <div className="relative w-16">
                                  <img
                                    src={subaccount.subAccountLogo}
                                    alt="subaccount Logo"
                                    className="rounded-md object-contain"
                                  />
                                </div>
                                <div className="flex flex-col flex-1">
                                  {subaccount.name}
                                  <span className="text-muted-foreground">
                                    {subaccount.address}
                                  </span>
                                </div>
                              </Link>
                            ) : (
                              <SheetClose asChild>
                                <Link
                                  to={`/subaccount/${subaccount.id}`}
                                  className="flex gap-4 w-full h-full"
                                >
                                  <div className="relative w-16">
                                    <img
                                      src={subaccount.subAccountLogo}
                                      alt="subaccount Logo"
                                      className="rounded-md object-contain"
                                    />
                                  </div>
                                  <div className="flex flex-col flex-1">
                                    {subaccount.name}
                                    <span className="text-muted-foreground">
                                      {subaccount.address}
                                    </span>
                                  </div>
                                </Link>
                              </SheetClose>
                            )}
                          </CommandItem>
                        ))
                      : "No Accounts"}
                  </CommandGroup>
                </CommandList>

                {(user?.role === "AGENCY_OWNER" ||
                  user?.role === "AGENCY_ADMIN") && (
                  <Button
                    className="w-full flex gap-2 mt-2"
                    onClick={() => setModalOpen(true)}
                  >
                    <PlusCircleIcon size={15} />
                    Create Sub Account
                  </Button>
                )}
              </Command>
            </PopoverContent>
          </Popover>

          <p className="text-muted-foreground text-xs mb-2">MENU LINKS</p>
          <Separator className="mb-4" />
          <nav className="relative">
            <Command className="rounded-lg">
              <CommandInput placeholder="Search..." />
              <CommandList className="py-4">
                <CommandEmpty>No Results Found</CommandEmpty>
                <CommandGroup className="gap-2">
                  {sidebarOpt.map((sidebarOptions: any) => {
                    let val;
                    const result = icons.find(
                      (icon: any) => icon.value === sidebarOptions.icon
                    );
                    if (result) {
                      val = <result.path />;
                    }

                    return (
                      <Link
                        key={sidebarOptions.id}
                        to={sidebarOptions.link}
                        className="w-full"
                      >
                        <CommandItem className="flex items-center gap-2 p-2 rounded-md transition-all w-full">
                          {val}
                          <span className="truncate flex-1">
                            {sidebarOptions.name}
                          </span>
                        </CommandItem>
                      </Link>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </nav>
        </div>
      </SheetContent>

      {modalOpen && (
        <CustomModal
          title="Create A Subaccount"
          subheading="You can switch between your agency account and the subaccount from the sidebar"
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <SubAccountDetails
          // agencyDetails={user?.Agency}
          // userId={user?.id}
          // userName={user?.name}
          />
        </CustomModal>
      )}
    </Sheet>
  );
};

export default MenuOptions;

