import LogoutButton from "../components/common/LogoutButton";
import React from "react";

const Home = () => {
  return (
    <div>
      <h3>Home Page</h3>
      <LogoutButton />
    </div>
  );
};

export default Home;
