import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

interface User {
  id: string;
  token: string;
  email: string;
  name: string | null;
  avatarUrl: string | null;
}

interface AuthResponse {
  token: string;
  user: User;
}

interface AuthPayload {
  token: string;
  email: string;
  name: string | null;
  avatarUrl: string | null;
}

// Base URL
const url = process.env.REACT_APP_BASE_URI as string;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}auth/`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResponse, AuthPayload>({
      query: (credentials) => ({
        url: "signin",
        method: "POST",
        body: credentials,
      }),
    }),


    
    signUp: builder.mutation<AuthResponse, AuthPayload>({
      query: (credentials) => ({
        url: "signup",
        method: "POST",
        body: credentials,
      }),
    }),

    getUserByEmail: builder.query({
      query: ({ email }) => ({
        url: "get-user-by-email",
        method: "GET",
        params: { email },
      }),
    }),


    // Others
  }),
});

export const { useSignInMutation, useSignUpMutation, useGetUserByEmailQuery } = authApi;
